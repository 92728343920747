.mdp-tree-table {
    color: #192c3d;
    background: theme('colors.white');
}

.mdp-tree-table .align-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mdp-tree-table .align-right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mdp-tree-table .cp_tree-table_viewport {
    overflow-y: auto;
    overflow-x: hidden;
}

/* Header */
.mdp-tree-table .cp_tree-table_header {
    background-color: #f9fafb;
    color: #374151;
    font-size: theme('fontSize.sm');
    font-weight: theme('fontWeight.medium');
    border-bottom: 1px solid theme('colors.gray.300');
}

.mdp-tree-table .cp_tree-table_row {
    border-bottom: 1px solid #d9e2ec;
}
/* Cells */
.mdp-tree-table .cp_tree-table_cell,
.mdp-tree-table .cp_tree-table_header-cell {
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.mdp-tree-table .cp_tree-table_header-cell > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mdp-tree-table .cp_tree-table_header-cell > span.align-right {
    text-align: right;
}

.mdp-tree-table .cp_tree-table_header-cell:first-child > span {
    padding-left: 15px;
}
