.mdp-tree-grid {
    color: #192c3d;
    background: theme('colors.white');
    box-shadow: 0px 4px 8px rgba(16, 42, 67, 0.06), 0px 8px 12px rgba(16, 42, 67, 0.1);
    border-radius: theme('spacing.2');
}

.mdp-tree-grid .cp_tree-table_viewport {
    overflow-y: auto;
    overflow-x: hidden;
}

/* Header */
.mdp-tree-grid .cp_tree-table_header {
    background-color: #f0f4f8;
    color: #627d98;
    padding: theme('padding.3') 0;
    height: 3.5rem !important;
    border-top-left-radius: theme('spacing.2');
    border-top-right-radius: theme('spacing.2');
    font-size: theme('fontSize.xs');
    font-weight: theme('fontWeight.semibold');
    text-transform: uppercase;
}

.mdp-tree-grid .cp_tree-table_row {
    border-bottom: 2px solid #d9e2ec;
}
/* Cells */
.mdp-tree-grid .cp_tree-table_cell,
.mdp-tree-grid .cp_tree-table_header-cell {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.mdp-tree-grid .cp_tree-table_header-cell > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mdp-tree-grid .cp_tree-table_header-cell > span.align-right {
    text-align: right;
}

.mdp-tree-grid .cp_tree-table_header-cell:first-child > span {
    padding-left: 15px;
}
