.platform-checkbox input[type='checkbox'],
.platform-checkbox .checked {
    display: none;
}

.platform-checkbox input[type='checkbox']:checked ~ .checked {
    display: inline-block;
}

.platform-checkbox input[type='checkbox']:checked ~ .unchecked {
    display: none;
}
