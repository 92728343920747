/*
  Main SCSS for all of the Stylesheets
*/
html,
body {
    display: block;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(237, 242, 247, 1);
    color: theme('colors.gray.500');
    overflow: hidden;
}

.Popover-tip {
    fill: white;
}

.Popover-above {
    z-index: 1000;
    margin-left: -30px;
}

select {
    -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/*! Firefox */
html {
    scrollbar-color: #fff #babac0;
}

/*! Other Browser */
html {
    --scrollbarBG: tranparent;
    --thumbBG: #a0a0a5;
}

body::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar {
    width: 8px;
}

body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}

body::-webkit-scrollbar-track:hover,
.scrollbar::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
}

body::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb:hover,
.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--thumbBG);
    border: 4px solid var(--scrollbarBG);
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button,
.scrollbar::-webkit-scrollbar-button {
    display: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
