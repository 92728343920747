.mdp-input {
    outline: none;
    padding: theme('spacing.2') theme('spacing.3');
    border: 1px solid theme('colors.gray.300');
    border-radius: theme('borderRadius.DEFAULT');
}

.mdp-input:hover:not(:disabled),
.mdp-input:active:not(:disabled),
.mdp-input:focus-within:not(:disabled),
.mdp-input:focus:not(:disabled) {
    box-shadow: theme('boxShadow.focus');
}

.mdp-input:disabled {
    background-color: theme('colors.gray.200');
    cursor: not-allowed;
}
