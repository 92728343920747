.ag-theme-alpine .ag-root-wrapper {
    border: solid 1px;
    border-color: transparent;
}

.ag-theme-alpine .ag-header {
    border-bottom: solid 1px var(--ag-row-border-color, var(--ag-gray-border-color, #dde2eb));
}

.ag-theme-alpine .ag-row {
    border-width: 1px 0px;
    line-height: 1.25rem;
}
