.mdp-table {
    background-color: theme('colors.white');
    min-width: 100%;
    color: theme('colors.gray.800');
}

.mdp-table-box {
    min-width: 100%;
    overflow-y: hidden;
    border-radius: theme('borderRadius.DEFAULT');
    box-shadow: theme('boxShadow.md');
}

.mdp-table thead {
    background-color: theme('colors.gray.50');
    color: theme('colors.gray.500');
    font-size: theme('fontSize.xs');
    font-weight: theme('fontWeight.semibold');
    text-transform: uppercase;
    border-bottom: 1px solid theme('colors.gray.200');
}

.mdp-table th {
    text-transform: uppercase;
    line-height: theme('lineHeight.4');
    letter-spacing: theme('letterSpacing.wider');
}

.mdp-table th,
.mdp-table td {
    text-align: left;
    padding: theme('spacing.3') theme('spacing.4');
}

.mdp-table th.text-center,
.mdp-table td.text-center {
    text-align: center;
}

.mdp-table th.text-right,
.mdp-table td.text-right {
    text-align: right;
}

.mdp-table th.text-left,
.mdp-table td.text-left {
    text-align: left;
}

.mdp-table td {
    height: theme('spacing.16');
}

.mdp-table th {
    height: theme('spacing.14');
    font-weight: theme('fontWeight.semibold');
}

.mdp-table th:first-child,
.mdp-table td:first-child {
    padding-left: theme('spacing.6');
}

.mdp-table th:last-child,
.mdp-table td:last-child {
    padding-right: theme('spacing.6');
}

.mdp-table tr {
    border-bottom: 1px solid theme('colors.gray.200');
}

.mdp-table tr:last-child {
    border-bottom: none;
}

.mdp-table-light thead {
    background-color: theme('colors.white');
    color: theme('colors.gray.800');
    border-bottom: 2px solid theme('colors.gray.200');
}

.mdp-table-tight th,
.mdp-table-tight td {
    padding: theme('spacing.2') theme('spacing.4');
}

.mdp-table-tight td {
    height: auto;
    vertical-align: top;
}

.mdp-table-tight th {
    height: auto;
}

.mdp-table-tight th:first-child,
.mdp-table-tight td:first-child {
    padding-left: theme('spacing.4');
}

.mdp-table-tight th:last-child,
.mdp-table-tight td:last-child {
    padding-right: theme('spacing.4');
}
